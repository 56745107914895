<template>
  <b-row class="match-height">

    <b-col cols="12">
      <h3>{{ $t('customer.edit.addresses-title') }}</h3>
    </b-col>

    <b-col
      v-for="(address, index) in customer.addresses"
      :key="address.id"
      cols="12"
      md="6"
      lg="4"
    >
      <b-card
        class="w-full"
        :title="address.name"
      >
        <div>
          <dl
            v-if="address.alias"
            class="row"
          >
            <dt class="col-4">
              {{ $t('customer.edit.alias') }}
            </dt>
            <dd class="col-8">
              {{ address.alias }}
            </dd>
          </dl>
          <dl
            v-if="address.name"
            class="row"
          >
            <dt class="col-4">
              {{ $t('customer.edit.name') }}
            </dt>
            <dd class="col-8">
              {{ address.name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-4">
              {{ $t('customer.edit.address') }}
            </dt>
            <dd class="col-8">
              {{ address.address }}<br>
              {{ address.zip }}
              {{ address.city }}
            </dd>
          </dl>
        </div>

        <template slot="footer">
          <div class="text-right">
            <b-button
              class="mr-1"
              variant="outline-danger"
              :disabled="loading"
              @click="removeAddress(index, address)"
            >
              {{ $t('shared.button.delete') }}
            </b-button>

            <b-button
              variant="primary"
              :disabled="loading"
              :to="{ name: 'address-edit', params: { id: address.id } }"
            >
              {{ $t('shared.button.see') }}
            </b-button>
          </div>
        </template>
      </b-card>
    </b-col>

    <b-col
      cols="12"
      md="6"
      lg="4"
    >
      <b-card
        class="w-full"
        :title="$t('customer.edit.add-address')"
      >
        <div>

          <v-select
            id="select-address"
            :options.sync="suggestions"
            :disabled="loading"
            :clearable="false"
            :filterable="false"
            label="name"
            :reduce="name => name.id"
            @search="fetchResults"
            @option:selected="onSelected"
          >
            <span slot="no-options">{{ $t('shared.write-something') }}</span>
            <template #option="{ name, alias, address, city }">
              <span>{{ name }}<br></span>
              <small v-if="alias"><strong>@{{ alias }}<br></strong></small>
              <small>{{ city }}, {{ address }}</small>
            </template>

          </v-select>

        </div>

      </b-card>
    </b-col>

  </b-row>
</template>
<script>
import { parseRequestError } from '@/helpers/helpers'
import {
  BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BButton,

    vSelect,
  },
  props: {
    customer: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    submitForm: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      suggestions: [],
      debounceMilliseconds: 150,
      shouldRenderSuggestions: (size, loading) => !loading && this.queryString.length > 2,
    }
  },
  methods: {
    fetchResults(queryString) {
      if (queryString.length < 2) return

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$store.dispatch('address/fetchFoundAddresses', { queryString, query: { withoutCustomer: true } })
          .then(res => {
            this.suggestions = []
            this.selected = null

            this.suggestions = res.data
          })
      }, this.debounceMilliseconds)
    },
    onSelected(option) {
      this.$store.commit('customer/ADD_ADDRESS_TO_CUSTOMER_EDITED_ADDRESSES', option)
      this.submit(option.id, this.customer.id)
      this.suggestions = []
    },
    removeAddress(index, address) {
      this.$store.commit('customer/REMOVE_ADDRESS_BY_INDEX_FROM_CUSTOMER_EDITED', index)
      this.submit(address.id, null)
    },
    submit(addressId, customerId = null) {
      this.$nextTick(() => {
        const payload = {
          id: addressId,
          data: {
            customer: customerId,
          },
        }

        this.$store.dispatch('address/patchAddress', payload)
          .then(() => {
            window.toast.notify.success(this.$t('customer.notify.edit.success_title'))
          })
          .catch(err => {
            parseRequestError(err)
            this.initCustomerData()
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
  },
}
</script>

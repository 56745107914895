<template>
  <div id="customer-edit">

    <custom-breadcrumbs
      :title="!empty(pageTitle) ? pageTitle : $t('shared.editing')"
      :meta="meta"
    />

    <b-tabs
      justified
      lazy
      pills
    >
      <validation-observer ref="editCustomerValidation">

        <b-tab :title="$t('customer.tabs.base-info')">

          <customer-billing-details
            :cancel-click="cancelClick"
            :customer="customer"
            :loading="loading"
            :show-map="showMap"
            :submit-form="submitForm"
          />

          <customer-addresses
            :customer.sync="customer"
            :loading="loading"
            :submit-form="submitForm"
          />

        </b-tab>

      </validation-observer>

      <b-tab
        v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])"
        :title="$t('customer.tabs.financial-info')"
      >

        <customer-billing-strategies
          :customer.sync="customer"
        />

      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
import { cloneNested, parseRequestError, empty } from '@/helpers/helpers'
import CustomerBillingDetails from '@/views/customers/CustomerEdit/CustomerBillingDetails.vue'
import CustomerBillingStrategies from '@/views/customers/CustomerEdit/CustomerBillingStrategies.vue'
import CustomerAddresses from '@/views/customers/CustomerEdit/CustomerAddresses.vue'
import { ValidationObserver } from 'vee-validate'
import { BTab, BTabs } from 'bootstrap-vue'
import guard from '@/guard'
import CustomBreadcrumbs from '@/layouts/components/rc/CustomBreadcrumbs.vue'

export default {
  components: {
    CustomBreadcrumbs,
    CustomerAddresses,
    CustomerBillingDetails,
    CustomerBillingStrategies,

    BTabs,
    BTab,

    ValidationObserver,
  },
  data() {
    return {
      showMap: false,
      loading: false,

      meta: {
        breadcrumb: [
          { text: 'order.orders_title' },
          { text: 'customer.menu.title', to: '/customer/list' },
          { text: 'customer.menu.edit', active: true },
        ],
        pageTitle: 'order.view_order',
      },
    }
  },
  computed: {
    customer: {
      set(customer) {
        return this.$store.commit('customer/SET_CUSTOMER_EDITED', customer)
      },
      get() {
        return cloneNested(this.$store.getters['customer/getCustomerData'])
      },
    },
    me() {
      return cloneNested(this.$store.getters['auth/getUser'])
    },
    pageTitle() {
      return this.customer.name
    },
  },
  watch: {
    customer: {
      handler() {
        this.showMap = false
        this.showMap = true
      },
      deep: true,
    },
  },
  created() {
    this.initCustomerData()
  },
  methods: {
    empty,
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    initCustomerData() {
      this.loading = true
      this.$store.dispatch('customer/fetchCustomerById', this.$route.params.id)
        .then(() => {
          this.$nextTick(() => {
            this.showMap = true
          })
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          this.loading = false
        })
    },
    submitForm() {
      this.$refs.editCustomerValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }
          this.loading = true
          const customerData = cloneNested(this.customer)
          const payload = { id: customerData.id, data: customerData }
          this.$store.dispatch('customer/updateCustomer', payload)
            .then(() => {
              this.loading = false
              window.toast.notify.success(this.$t('customer.notify.edit.success_title'))
            })
            .catch(err => {
              parseRequestError(err)
              this.initCustomerData()
              this.loading = false
            })

          return true
        })
    },
    cancelClick() {
      return this.$router.back()
    },
  },
}
</script>

<template>
  <b-row class="match-height">

    <b-col
      md="6"
      cols="12"
    >
      <b-card :title="$t('customer.edit.billing-details')">

        <rc-overlay :show="loading">
          <form
            id="editCustomerForm"
            @submit.prevent
          >
            <b-row>
              <b-col
                cols="12"
                class="pb-1"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('customer.label.name')"
                  rules="required|min:3|max:100"
                >
                  <label for="name">
                    {{ $t('customer.label.name') }}
                  </label>
                  <b-form-input
                    id="name"
                    v-model="customer.name"
                    type="text"
                    minlength="3"
                    maxlength="100"
                    :placeholder="$t('customer.label.name')"
                    autocomplete="chrome-off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                class="pb-1"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('customer.label.street_number')"
                  rules="required|min:3|max:100"
                >
                  <label for="addressLine1">
                    {{ $t('customer.label.street_number') }}
                  </label>
                  <b-form-input
                    id="addressLine1"
                    v-model="customer.addressLine1"
                    type="text"
                    minlength="3"
                    maxlength="100"
                    :placeholder="$t('customer.label.street_number')"
                    autocomplete="chrome-off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <!--
              <b-col
                cols="12"
                class="pb-1"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('customer.label.street_number')"
                  rules="required|min:3|max:100"
                >
                  <label for="addressLine2">
                    {{ $t('customer.label.street_number') }}
                  </label>
                  <b-form-input
                    id="addressLine2"
                    v-model="customer.addressLine2"
                    type="text"
                    minlength="3"
                    maxlength="100"
                    :placeholder="$t('customer.label.street_number')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              -->

              <b-col
                cols="12"
                class="pb-1"
              >
                <b-row>
                  <b-col
                    cols="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('customer.label.zip')"
                      rules="required|min:3|max:10"
                    >
                      <label for="zip">
                        {{ $t('customer.label.zip') }}
                      </label>
                      <b-form-input
                        id="zip"
                        v-model="customer.zip"
                        type="text"
                        minlength="3"
                        maxlength="10"
                        :placeholder="$t('customer.label.zip')"
                        autocomplete="chrome-off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="8"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('customer.label.city')"
                      rules="required|min:3|max:50"
                    >
                      <label for="city">
                        {{ $t('customer.label.city') }}
                      </label>
                      <b-form-input
                        id="city"
                        v-model="customer.city"
                        type="text"
                        minlength="3"
                        maxlength="50"
                        :placeholder="$t('customer.label.city')"
                        autocomplete="chrome-off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                cols="12"
                class="pb-1"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('customer.label.tax-number')"
                  rules="alpha-num|min:12|max:12"
                >
                  <label for="taxNumber">
                    {{ $t('customer.label.tax-number') }}
                  </label>
                  <b-form-input
                    id="taxNumber"
                    v-model="customer.taxNumber"
                    type="text"
                    minlength="12"
                    maxlength="12"
                    placeholder="PL0000000000"
                    autocomplete="chrome-off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

            </b-row>
          </form>
        </rc-overlay>

        <template #footer>

          <div class="text-right">
            <b-button
              class="mr-1"
              variant="outline-warning"
              @click="$router.back()"
            >
              {{ $t('shared.button.cancel') }}
            </b-button>

            <b-button
              variant="primary"
              :disabled="loading"
              @click="submitForm"
            >
              {{ $t('shared.button.submit') }}
            </b-button>
          </div>

        </template>

      </b-card>
    </b-col>

    <b-col
      md="6"
      cols="12"
    >
      <b-card
        no-body
        no-title
        no-footer
      >
        <iframe
          v-if="showMap"
          class="border-0 h-100 rounded"
          :src="mapUrl()"
          allowfullscreen
        />
      </b-card>
    </b-col>

  </b-row>
</template>
<script>
import {
  BButton,
  BCard,
  BCol, BFormInput, BRow,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
// import { required, alphaNum, digits } from '@validations'

export default {
  components: {
    BFormInput,
    BCol,
    BRow,
    BCard,
    BButton,
    RcOverlay,

    ValidationProvider,
  },
  props: {
    cancelClick: {
      type: Function,
      default: () => {},
    },
    customer: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showMap: {
      type: Boolean,
      default: false,
    },
    submitForm: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    mapUrl() {
      const queryParams = {
        key: 'AIzaSyB5zbQZqenSwG_xowvr2lQ4_HfU_ZD-CQY',
        q: `${this.customer.city} ${this.customer.zip}, ${this.customer.addressLine1}`,
      }

      return `https://www.google.com/maps/embed/v1/place?${Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&')}`
    },
  },
}
</script>

<template>
  <div>
    <validation-observer ref="editCustomerBillingStrategyValidation">
      <b-row class="match-height">

        <b-col cols="12">
          <b-card :title="$t('customer.billing-strategies.title')">

            <rc-overlay :show="loading">
              <form
                id="editCustomerBillingStrategyForm"
                @submit.prevent
              >

                <b-row>
                  <b-col
                    v-if="customerBillingStrategies.length === 0"
                    cols="12"
                    class="pb-1 text-center"
                  >
                    {{ $t('customer.billing-strategies.no-strategies') }}
                  </b-col>
                </b-row>

                <div
                  v-for="(customerBillingStrategy, index) in customerBillingStrategies"
                  :key="customerBillingStrategy.id"
                  class="py-1 border-bottom"
                >

                  <b-row>
                    <b-col
                      cols="12"
                      md="7"
                    >
                      <label for="select-type">
                        {{ $t('customer.billing-strategies.strategy-type') }}
                      </label>
                      <v-select
                        id="select-type"
                        v-model="customerBillingStrategy.type"
                        :clearable="false"
                        :searchable="false"
                        :options="types"
                        :disabled="disableTypeSelect(customerBillingStrategy)"
                      >
                        <span
                          slot="selected-option"
                          slot-scope="{label}"
                        >
                          {{ $t(`customer.billing-strategies.types.${label}`) }}
                        </span>
                        <template #option="{label}">
                          {{ $t(`customer.billing-strategies.types.${label}`) }}
                        </template>

                      </v-select>
                    </b-col>

                    <b-col
                      cols="12"
                      md="5"
                    >
                      <label for="select-order-type">
                        {{ $t('customer.billing-strategies.order-type') }}
                      </label>
                      <v-select
                        id="select-order-type"
                        v-model="customerBillingStrategy.orderType"
                        :clearable="false"
                        :searchable="false"
                        :options="orderTypes"
                      >
                        <span
                          slot="selected-option"
                          slot-scope="{label}"
                        >
                          {{ $t(`order.types.${label}`) }}
                        </span>
                        <template #option="{label}">
                          {{ $t(`order.types.${label}`) }}
                        </template>

                      </v-select>
                    </b-col>
                  </b-row>

                  <b-row class="justify-content-between pt-1">
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('customer.billing-strategies.price-gross')"
                        rules="required"
                      >
                        <label for="hourlySalaryGross">
                          {{ $t('customer.billing-strategies.price-gross') }}
                        </label>
                        <b-input-group>
                          <b-form-input
                            id="hourlySalaryGross"
                            v-model="customerBillingStrategy.priceGross"
                            type="number"
                            step="0.01"
                            :placeholder="$t('customer.billing-strategies.price-gross')"
                            autocomplete="off"
                          />
                          <b-input-group-append is-text>
                            {{ $t('shared.currency-symbol') }}
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('customer.billing-strategies.return-transport-surcharge-gross')"
                      >
                        <label for="returnTransportSurchargeGross">
                          {{ $t('customer.billing-strategies.return-transport-surcharge-gross') }}
                        </label>
                        <b-input-group>
                          <b-form-input
                            id="returnTransportSurchargeGross"
                            v-model="customerBillingStrategy.returnTransportSurchargeGross"
                            type="number"
                            step="1"
                            :placeholder="$t('customer.billing-strategies.return-transport-surcharge-gross')"
                            autocomplete="off"
                          />
                          <b-input-group-append is-text>
                            {{ $t('shared.currency-symbol') }}
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('customer.billing-strategies.infectious-disease-surcharge')"
                      >
                        <label
                          v-b-tooltip.hover
                          for="infectiousDisease"
                          :title="$t('customer.billing-strategies.infectious-disease-surcharge-description')"
                        >
                          {{ $t('customer.billing-strategies.infectious-disease-surcharge') }}
                          <feather-icon
                            icon="InfoIcon"
                            size="12"
                          />
                        </label>
                        <b-input-group>
                          <b-form-input
                            id="infectiousDisease"
                            v-model="customerBillingStrategy.infectiousDiseaseSurchargeGross"
                            type="number"
                            step="1"
                            :placeholder="$t('customer.billing-strategies.infectious-disease-surcharge')"
                            autocomplete="off"
                          />
                          <b-input-group-append is-text>
                            {{ $t('shared.currency-symbol') }}
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <b-row class="justify-content-between pt-1">
                    <b-col
                      v-if="customerBillingStrategy.type === 'kilometer_fee'"
                      cols="auto"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('customer.billing-strategies.calculate-distance-from-km')"
                      >
                        <label for="calculateDistanceFromKm">
                          {{ $t('customer.billing-strategies.calculate-distance-from-km') }}
                        </label>
                        <b-form-input
                          id="calculateDistanceFromKm"
                          v-model="customerBillingStrategy.calculateDistanceFromKm"
                          type="number"
                          step="1"
                          :placeholder="$t('customer.billing-strategies.calculate-distance-from-km')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      v-if="customerBillingStrategy.type === 'hourly_fee'"
                      cols="auto"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('customer.billing-strategies.calculate-time-from-minute')"
                      >
                        <label for="calculateTimeFromMinute">
                          {{ $t('customer.billing-strategies.calculate-time-from-minute') }}
                        </label>
                        <b-form-input
                          id="calculateTimeFromMinute"
                          v-model="customerBillingStrategy.calculateTimeFromMinute"
                          type="number"
                          step="1"
                          :placeholder="$t('customer.billing-strategies.calculate-distance-from-km')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      v-if="['kilometer_fee', 'hourly_fee'].includes(customerBillingStrategy.type)"
                      cols="auto"
                    >
                      <b-form-checkbox
                        v-model="customerBillingStrategy.calculateFromBaseToBase"
                        v-b-tooltip.hover
                        :title="$t('customer.billing-strategies.calculate-from-base-to-base-description')"
                        class="my-2"
                      >
                        {{ $t('customer.billing-strategies.calculate-from-base-to-base') }}
                      </b-form-checkbox>
                    </b-col>

                    <b-col
                      v-if="['kilometer_fee', 'hourly_fee'].includes(customerBillingStrategy.type)"
                      cols="auto"
                    >
                      <b-form-checkbox
                        v-model="customerBillingStrategy.addReturnWayInOneWayOrder"
                        v-b-tooltip.hover
                        :title="$t('customer.billing-strategies.add-return-way-in-one-way-order-description')"
                        class="my-2"
                      >
                        {{ $t('customer.billing-strategies.add-return-way-in-one-way-order') }}
                      </b-form-checkbox>
                    </b-col>

                    <b-col
                      cols="12"
                      md="1"
                      class="ml-auto"
                    >
                      <b-button
                        class="mt-2 btn-block"
                        variant="flat-danger"
                        :disabled="loading"
                        @click="remove(index)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </form>
            </rc-overlay>

            <template #footer>

              <div class="float-left">

                <b-button
                  variant="primary"
                  :disabled="loading"
                  class="mr-1"
                  @click="addNew"
                >
                  {{ $t('shared.button.add') }}
                </b-button>

                <b-button-group>
                  <b-button
                    variant="outline-secondary"
                    :disabled="loading"
                    @click="recalculateRequest(moment().format('YYYY-MM'))"
                  >
                    {{ $t('customer.billing-strategies.request-recalculation-current-month') }}
                  </b-button>
                  <b-dropdown
                    variant="outline-secondary"
                    right
                    :disabled="loading"
                  >
                    <b-dropdown-item
                      @click="recalculateRequest(moment().subtract(1, 'months').format('YYYY-MM'))"
                    >{{ $t('customer.billing-strategies.request-recalculation-prev-month') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-button-group>
              </div>

              <div class="float-right">
                <!--
            <b-button
              class="mr-1"
              variant="outline-warning"
              @click="$router.back()"
            >
              {{ $t('shared.button.cancel') }}
            </b-button>
            -->

                <b-button
                  variant="success"
                  :disabled="loading"
                  @click="submitForm"
                >
                  {{ $t('shared.button.submit') }}
                </b-button>
              </div>

            </template>

          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>
import {
  BButton, BButtonGroup,
  BCard,
  BCol, BDropdown, BDropdownItem, BFormCheckbox, BFormInput, BInputGroup, BInputGroupAppend, BRow, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import { parseRequestError, empty } from '@/helpers/helpers'
// import { required, alphaNum, digits } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment/moment'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    RcOverlay,

    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,

    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    customer: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      loading: false,
      customerBillingStrategies: [],
      types: [
        'fixed_fee',
        'hourly_fee',
        'kilometer_fee',
        // 'surcharge',
      ],
    }
  },
  computed: {
    orderTypes() {
      return this.$store.state.order.types
    },
  },
  beforeMount() {
    this.init()
  },
  methods: {
    moment,
    init() {
      this.loading = true
      this.$store.dispatch('customer/fetchCustomerBillingStrategiesByCustomerId', this.$route.params.id)
        .then(res => {
          this.customerBillingStrategies = res.data
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          this.loading = false
        })
    },
    addNew() {
      this.customerBillingStrategies.push({
        customer: this.customer.id,
        sort: this.customerBillingStrategies.length + 1,
        type: 'fixed_fee',
        orderType: 'specialized_transport',
        priceGross: null,
        calculateDistanceFromKm: null,
        calculateTimeFromMinute: null,
        infectiousDiseaseSurchargeGross: null,
        returnTransportSurchargeGross: null,
        calculateFromBaseToBase: false,
        addReturnWayInOneWayOrder: false,
      })
    },
    remove(index) {
      this.customerBillingStrategies.splice(index, 1)
    },
    submitForm() {
      this.$refs.editCustomerBillingStrategyValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }
          this.loading = true
          const payload = { id: this.customer.id, data: { customerBillingStrategies: this.customerBillingStrategies } }
          this.$store.dispatch('customer/updateCustomerBillingStrategies', payload)
            .then(() => {
              this.loading = false
              window.toast.notify.success(this.$t('customer.notify.edit.success_title'))
            })
            .catch(err => {
              parseRequestError(err)
              this.loading = false
            })

          return true
        })
    },
    disableTypeSelect(customerBillingStrategy) {
      return !empty(customerBillingStrategy.calculateTimeFromMinute) || !empty(customerBillingStrategy.calculateDistanceFromKm) || customerBillingStrategy.calculateFromBaseToBase
    },
    recalculateRequest(month) {
      this.loading = true
      this.$store.dispatch('order/requestOrderSummaryRecalculationForMonth', { month, customerId: this.$route.params.id })
        .then(() => {
          window.toast.notify.success(
            this.$t('customer.billing-strategies.recalculation-requested'),
            this.$t('customer.billing-strategies.recalculation-requested-description'),
          )
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
.dropdown-toggle {
  &:after {
    left: auto;
    right: auto;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-card',{attrs:{"title":_vm.$t('customer.edit.billing-details')},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-warning"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" "+_vm._s(_vm.$t('shared.button.cancel'))+" ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t('shared.button.submit'))+" ")])],1)]},proxy:true}])},[_c('rc-overlay',{attrs:{"show":_vm.loading}},[_c('form',{attrs:{"id":"editCustomerForm"},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('customer.label.name'),"rules":"required|min:3|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t('customer.label.name'))+" ")]),_c('b-form-input',{attrs:{"id":"name","type":"text","minlength":"3","maxlength":"100","placeholder":_vm.$t('customer.label.name'),"autocomplete":"chrome-off"},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('customer.label.street_number'),"rules":"required|min:3|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"addressLine1"}},[_vm._v(" "+_vm._s(_vm.$t('customer.label.street_number'))+" ")]),_c('b-form-input',{attrs:{"id":"addressLine1","type":"text","minlength":"3","maxlength":"100","placeholder":_vm.$t('customer.label.street_number'),"autocomplete":"chrome-off"},model:{value:(_vm.customer.addressLine1),callback:function ($$v) {_vm.$set(_vm.customer, "addressLine1", $$v)},expression:"customer.addressLine1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('customer.label.zip'),"rules":"required|min:3|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"zip"}},[_vm._v(" "+_vm._s(_vm.$t('customer.label.zip'))+" ")]),_c('b-form-input',{attrs:{"id":"zip","type":"text","minlength":"3","maxlength":"10","placeholder":_vm.$t('customer.label.zip'),"autocomplete":"chrome-off"},model:{value:(_vm.customer.zip),callback:function ($$v) {_vm.$set(_vm.customer, "zip", $$v)},expression:"customer.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('customer.label.city'),"rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"city"}},[_vm._v(" "+_vm._s(_vm.$t('customer.label.city'))+" ")]),_c('b-form-input',{attrs:{"id":"city","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('customer.label.city'),"autocomplete":"chrome-off"},model:{value:(_vm.customer.city),callback:function ($$v) {_vm.$set(_vm.customer, "city", $$v)},expression:"customer.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('customer.label.tax-number'),"rules":"alpha-num|min:12|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"taxNumber"}},[_vm._v(" "+_vm._s(_vm.$t('customer.label.tax-number'))+" ")]),_c('b-form-input',{attrs:{"id":"taxNumber","type":"text","minlength":"12","maxlength":"12","placeholder":"PL0000000000","autocomplete":"chrome-off"},model:{value:(_vm.customer.taxNumber),callback:function ($$v) {_vm.$set(_vm.customer, "taxNumber", $$v)},expression:"customer.taxNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)])],1)],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-card',{attrs:{"no-body":"","no-title":"","no-footer":""}},[(_vm.showMap)?_c('iframe',{staticClass:"border-0 h-100 rounded",attrs:{"src":_vm.mapUrl(),"allowfullscreen":""}}):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }